import $ from "jquery";
window.$ = window.jQuery = $

$(document).ready(function(){
    var top = $('#top_bar').outerHeight();
    //

    //
    // Add smooth scrolling on all links inside the navbar
    $("#navbar a, .scrollto").on('click', function(event) {
        $('#navbar').collapse('hide');

        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;

            $('html, body').animate({
                scrollTop: (($(hash).offset().top)-top)
            }, 600);
        }
    });
});

$(window).on('load scroll resize orientationchange', function () {
    let viewportWidth = $(window).width();

    let top_bar = $("#top_bar")

    if ($(document).scrollTop()) {
        top_bar.removeClass(" shadow");
        top_bar.addClass("fixed-top shadow");

        if (viewportWidth <= 991.98){
            $('.headerimage').css({ 'padding-top': top_bar.height()+'px' });
        }
    } else {
        top_bar.removeClass(" shadow");
        top_bar.removeClass("fixed-top shadow");
        $('.headerimage').css({ 'padding-top': '0px' });

        if (viewportWidth >= 991.98) {
            top_bar.addClass("");
        }
    }
});

$(document).ready(function() {
    $('#Carousel').carousel({
        interval: 4000
    })
});
